import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M748 4183 c-10 -2 -18 -12 -18 -22 0 -14 14 -19 80 -28 123 -16 159
-49 167 -157 6 -72 14 -64 -87 -87 -243 -54 -494 -224 -657 -444 -195 -262
-274 -618 -208 -935 75 -362 316 -675 640 -830 55 -27 119 -54 143 -61 23 -7
42 -16 42 -20 0 -4 -26 -9 -57 -11 -51 -3 -58 -6 -61 -26 l-3 -22 456 0 456 0
-3 23 c-3 19 -10 22 -48 25 -96 5 -101 12 -25 36 151 49 269 120 452 270 223
184 392 371 589 651 103 146 114 162 210 310 l59 90 3 -594 c2 -550 1 -597
-15 -630 -21 -43 -59 -63 -131 -69 -42 -4 -52 -8 -50 -21 3 -14 39 -16 351
-19 333 -2 347 -2 347 16 0 16 -10 20 -59 25 -65 6 -102 25 -119 63 -9 18 -12
156 -12 474 l0 449 178 3 c137 2 179 0 187 -10 26 -33 186 -42 293 -17 80 19
157 58 265 135 97 70 135 112 172 193 51 110 57 266 15 384 -26 74 -121 216
-189 283 -96 94 -263 160 -402 160 -69 0 -181 -31 -259 -71 l-75 -39 -342 0
c-246 -1 -343 -4 -343 -12 0 -32 -23 27 -105 270 l-90 267 -865 1 c-476 1
-873 0 -882 -3z m1345 -67 c123 -20 215 -59 292 -122 82 -68 137 -142 202
-274 l53 -105 73 -1 c90 0 136 -23 154 -74 8 -23 12 -118 12 -283 l1 -248
-135 -207 c-211 -323 -287 -425 -458 -613 -177 -196 -429 -397 -607 -484 -119
-59 -227 -90 -247 -73 -42 34 -43 51 -43 672 0 543 1 595 16 598 9 2 141 0
293 -3 194 -4 286 -10 311 -19 146 -56 196 -159 207 -425 2 -71 6 -135 8 -142
2 -6 13 -9 24 -6 21 5 21 8 21 605 l0 599 -22 -3 c-22 -3 -23 -8 -29 -133 -12
-228 -60 -331 -180 -384 -65 -29 -192 -41 -436 -41 l-213 0 0 597 0 596 308
-6 c194 -4 339 -12 395 -21z m-1113 -1322 c0 -973 -2 -1089 -16 -1120 -27 -56
-35 -59 -116 -34 -370 112 -668 416 -773 790 -75 266 -52 552 65 797 96 201
234 365 403 476 54 36 167 96 225 119 26 10 189 56 205 57 4 1 7 -488 7 -1085z
m2837 931 c43 -8 105 -28 138 -45 60 -29 175 -115 175 -129 0 -5 -37 7 -82 25
-108 43 -277 72 -458 78 -80 2 -147 5 -150 6 -3 0 15 11 40 24 39 21 68 29
175 50 41 8 86 6 162 -9z m-558 -180 c-34 -41 -63 -75 -65 -75 -2 0 -4 34 -4
75 l0 75 66 0 65 0 -62 -75z m424 49 c85 -19 115 -34 182 -86 97 -77 144 -187
152 -358 10 -209 -47 -339 -178 -406 -87 -43 -158 -54 -411 -61 l-238 -6 0
368 0 368 53 66 c30 36 72 83 93 105 l39 38 118 -6 c64 -3 150 -13 190 -22z
m247 -924 c-32 -18 -154 -40 -226 -40 -109 0 -82 16 38 23 57 4 117 11 133 16
17 5 39 10 50 10 16 0 17 -1 5 -9z"/>
<path d="M920 1235 l0 -145 45 0 45 0 0 50 0 50 60 0 60 0 0 35 0 35 -60 0
c-57 0 -60 1 -60 25 0 24 2 25 70 25 l70 0 0 35 0 35 -115 0 -115 0 0 -145z"/>
<path d="M1434 1333 c-9 -26 -32 -88 -51 -137 -18 -48 -33 -92 -33 -97 0 -5
20 -9 44 -9 36 0 46 4 51 20 5 17 15 20 60 20 44 0 53 -3 58 -20 4 -16 14 -20
51 -20 30 0 46 4 46 13 0 6 -22 71 -49 142 l-48 130 -55 3 -56 3 -18 -48z m96
-119 c0 -17 -6 -21 -26 -21 -25 0 -26 1 -20 40 4 22 10 45 13 51 8 12 32 -40
33 -70z"/>
<path d="M1915 1368 c-31 -17 -45 -39 -45 -74 0 -47 27 -72 99 -92 69 -18 88
-37 47 -48 -32 -8 -66 3 -66 22 0 12 -10 14 -42 12 -39 -3 -43 -5 -40 -28 5
-44 35 -64 107 -68 35 -2 76 -1 89 2 40 10 66 45 66 87 0 54 -19 69 -128 98
-48 14 -52 34 -7 39 18 2 31 -2 37 -12 9 -16 83 -23 91 -8 10 15 -13 52 -42
66 -35 18 -136 21 -166 4z"/>
<path d="M2330 1345 c0 -33 2 -35 34 -35 65 0 66 -2 66 -116 l0 -104 45 0 44
0 3 108 c2 76 7 107 16 108 6 1 28 2 47 3 33 1 35 3 35 36 l0 35 -145 0 -145
0 0 -35z"/>
<path d="M2830 1235 l0 -146 48 3 47 3 0 40 1 40 49 5 c84 9 115 37 115 104 0
22 -9 45 -24 63 -23 27 -28 28 -130 31 l-106 4 0 -147z m155 45 c0 -21 -5 -26
-32 -28 -30 -3 -32 -1 -31 28 1 29 4 31 32 28 26 -2 31 -7 31 -28z"/>
<path d="M3341 1242 c-28 -76 -51 -141 -51 -145 0 -4 20 -7 44 -7 36 0 46 4
51 20 5 17 15 20 60 20 44 0 53 -3 58 -20 4 -16 14 -20 51 -20 33 0 46 4 46
14 0 8 -22 72 -49 143 l-49 128 -55 3 -54 3 -52 -139z m129 -28 c0 -16 -6 -21
-25 -21 -29 0 -32 15 -13 68 l12 34 12 -30 c7 -17 13 -40 14 -51z"/>
<path d="M3790 1377 c0 -2 25 -42 55 -88 51 -77 55 -89 55 -141 l0 -58 50 0
50 0 0 64 c0 39 5 70 13 78 7 7 32 43 55 81 l42 67 -53 0 c-53 0 -53 0 -75
-45 -12 -25 -26 -45 -30 -45 -5 0 -20 20 -33 45 -24 45 -24 45 -76 45 -29 0
-53 -2 -53 -3z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
